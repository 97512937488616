_context.invoke('Nittro', function () {

    var Object = _context.extend(function () {
        this._ = { };

    }, {

    });

    _context.mixin(Object, 'Nittro.EventEmitter');
    _context.register(Object, 'Object');

});
