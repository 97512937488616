_context.invoke(function(Nittro) {
    var builder = new Nittro.DI.ContainerBuilder({
        "params": {},
        "extensions": {
            "ajax": "Nittro.Ajax.Bridges.AjaxDI.AjaxExtension()",
            "page": "Nittro.Page.Bridges.PageDI.PageExtension()",
            "forms": "Nittro.Forms.Bridges.FormsDI.FormsExtension()",
            "flashes": "Nittro.Flashes.Bridges.FlashesDI.FlashesExtension()",
            "routing": "Nittro.Routing.Bridges.RoutingDI.RoutingExtension()",
            "checklist": "Nittro.Extras.CheckList.Bridges.CheckListDI.CheckListExtension()",
            "keymap": "Nittro.Extras.Keymap.Bridges.KeymapDI.KeymapExtension()",
            "dialogs": "Nittro.Extras.Dialogs.Bridges.DialogsDI.DialogsExtension()",
            "confirm": "Nittro.Extras.Confirm.Bridges.ConfirmDI.ConfirmExtension()",
            "dropzone": "Nittro.Extras.DropZone.Bridges.DropZoneDI.DropZoneExtension()",
            "paginator": "Nittro.Extras.Paginator.Bridges.PaginatorDI.PaginatorExtension()",
            "storage": "Nittro.Extras.Storage.Bridges.StorageDI.StorageExtension()"
        },
        "services": {},
        "factories": {}
    });

    this.di = builder.createContainer();
    this.di.runServices();

});